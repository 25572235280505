import React from "react"
import Layout from "../components/Layout"
import Title from "../components/Title"


const contact = () => {
  return <Layout>
    <section className="contact-page">
      <Title title="Let's talk :)" />
      <p>Feel free to shoot me a message on <a href="https://wa.me/33786138709">whatsapp</a>, or drop your info in this form and I'll get back to you soon :)</p>

      <article className="contact-form">

        <h3>Get in touch</h3>

        <form action="https://formspree.io/f/mjvzalbo" method="POST">
          <div className="form-group">
            <input type="text" name="name" placeholder="name" className="form-control" />
            <input type="email" name="email" placeholder="email" className="form-control" />
            <textarea name="message" rows="5" placeholder="message" className="form-control" />

          </div>
          <button type="submit" className="submit-btn btn">Submit</button>
        </form>
      </article>
    </section>  </Layout>
}

export default contact
